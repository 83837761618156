import React from "react";
import Card from "./card";

export default function PortfolioCard(): React.ReactElement {
	return (
		<Card id={"EducationCard"} title={"Portfolio"}>
			<h2>Coding</h2>
			<h3>Web-Based IDE for BF</h3>
			<p>React & TypeScript IDE for editing BF Language code: <a href="./alg-bf">Live Demo</a> | <a href="https://github.com/TheAlyxGreen/ALG-BF">GitHub</a></p>
			<h3>My GitHub</h3>
			<p>Miscellaneous projects I've published: <a href="https://github.com/TheAlyxGreen">GitHub</a></p>
			<h2>Writing</h2>
			<h3>Example Academic Writing</h3>
			<p>Final paper for POLS 480: <a href="https://docs.google.com/document/d/1g0cFgH1oxjWOeC9EtKvjJS6cBkLh0PC7CXQZT3jyCqE/edit?usp=sharing">Google Drive</a></p>
			<h2>Graphic Design</h2>
			<h3>Class Projects</h3>
			<p>Design processes for various graphic design projects: <a href="https://dribbble.com/TheAlyxGreen">Dribbble</a></p>
		</Card>
	);
}