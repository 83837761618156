import React from "react";
import "./card.css";

export type cardProps = {
	id: string,
	title: string,
	children: any,
}
export default function Index(props: cardProps): React.ReactElement {
	return (
		<div className="card" id={props.id}>
			<h1 className="card-title">{props.title}</h1>
			<div className="card-content">
				{props.children}
			</div>
		</div>
	);
}