import React from "react";

import NamePlate from "./components/name-plate";
import handleScroll from "./event-handlers/on-scroll";
import EducationCard from "./components/education-card";
import WorkCard from "./components/work-card";
import SkillsCard from "./components/skills-card";
import PortfolioCard from "./components/portfolio-card";

let hasLoaded = false;
export default function App() {
	if (!hasLoaded) {
		setTimeout(() => {
			document.getElementById("page")?.addEventListener("scroll", handleScroll);
			document.addEventListener("scroll", handleScroll);
			hasLoaded = true;
		}, 10);
	}
	return (
		<div id="page">
			<NamePlate/>
			<div id="Content">
				<EducationCard/>
				<WorkCard/>
				<PortfolioCard/>
				<SkillsCard/>
			</div>
		</div>
	);
}
