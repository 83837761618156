import React from "react";
import Card from "./card";

export default function EducationCard(): React.ReactElement {
	return (
		<Card id={"EducationCard"} title={"Education"}>
			<h2>Southern Illinois University</h2>
			<p>August 2019 – May 2023</p>
			<h3>Majors:</h3>
			<p>Computer Science with a specialization in Distributed Computing</p>
			<p>Political Science with a specialization in International Affairs</p>
			<h3>Minors:</h3>
			<p>Economics</p>
			<p>Philosophy</p>
		</Card>
	);
}