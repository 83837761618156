import React from "react";
import Card from "./card";

export default function WorkCard(): React.ReactElement {
	return (
		<Card id="WorkCard" title={"Work"}>
			<h2>Web Developer</h2>
			<h3>Physician's Postgraduate Press</h3>
			<p>August 2018 – August 2019</p>
			<h3>Accomplishments</h3>
			<p>
				<ul>
					<li>Converted print issues of the Journal of Clinical Psychiatry from Adobe design files to HTML web
						pages
					</li>
					<li>Lead a project to create a web application in JavaScript to automate the process of converting
						those journal issues to HTML and publish to a Microsoft Sharepoint server
					</li>
					<li>Completed needs assessment to develop strategy and solution aligned with needs</li>
					<li>Partnered with business leaders and stakeholders during discovery, design and deployment
						activities to ensure project success
					</li>
					<li>Identified and implemented opportunities to drive additional efficiency and value beyond
						original scope of work through automating jobs
					</li>
				</ul>

			</p>
		</Card>
	);
}