export default function handleScroll(e: Event) {
	const se              = e as WheelEvent;
	let scrollTop: number = 0;
	// @ts-ignore
	scrollTop             = se.target?.scrollTop;
	let scrollPercent1    = 1 - (4 * scrollTop / window.innerHeight);
	let scrollPercent2    = (2 * scrollTop / window.innerHeight);

	let root: HTMLElement = document.querySelector(":root") as HTMLElement;
	root.style.setProperty("--nameplate-opacity", `${scrollPercent1}`);
	root.style.setProperty("--content-opacity", `${(scrollPercent2)}`);
}