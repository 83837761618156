import React from "react";
import Card from "./card";

export default function SkillsCard(): React.ReactElement {
	return (
		<Card id={"EducationCard"} title={"Skills"}>
			<h3>Front-End Development</h3>
			<p>HTML, CSS, JavaScript, TypeScript, React, PHP, Wordpress, Tailwind, RESTful/SOAP APIs</p>
			<h3>Back-End Development</h3>
			<p>GoLang, Java, C, C#, Node.js, Distributed Systems, Microservices, Google Cloud Platform (GCP), Amazon Web Services (AWS), Containerization, Virtualization, Docker</p>
			<h3>Data Science</h3>
			<p>Python, R, Selenium, RESTful/SOAP APIs, SQL, Relational Databases (SQL, Postgres, etc), Non-Relational Databases (Mongo), Microsoft Excel, Microsoft Access</p>
			<h3>Graphic Design</h3>
			<p>Adobe Suite (Photoshop, InDesign, etc.), Affinity Suite (Photo, Designer, Publisher), Miro, Figma</p>
			<h3>General</h3>
			<p>Strong Written & Oral Communication, Microsoft Office Suite, Academic Writing, Research, International Law, Git, Github</p>
		</Card>
	);
}