import React from "react";
import "./name-plate.css";

export default function Index(): React.ReactElement {
	return (
		<div id="NamePlate" className="no-select">
			<div id="NamePlate-First" className="NamePlateText">The</div>
			<div id="NamePlate-Second" className="NamePlateText">Alyx</div>
			<div id="NamePlate-Third" className="NamePlateText">Green</div>
		</div>
	);
}